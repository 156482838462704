
import {createRouter, createWebHistory} from "vue-router";

import LoginAdmin from '@/views/LoginAdmin'
import LoginView from '@/views/LoginView'
import RegisterView from '@/views/RegisterView'
import Dashboard from "@/views/Dashboard";
import SchoolCreateView from "@/views/SchoolCreateView";
import ClassesView from "@/views/ClassesView";
import ClasseView from "@/views/ClasseView";
import StudentView from "@/views/StudentView";
import TeacherView from "@/views/TeacherView";
import ChallengeView from "@/views/ChallengeView";
import LogoutView from "@/views/LogoutView";
import StudentChallengesView from "@/views/StudentChallengesView";
import StudentOwnChallengesView from "@/views/StudentOwnChallengesView";
import StudentOwnDailyChallengesView from "@/views/StudentOwnDailyChallengesView";
import StudentDailyChallengesView from "@/views/StudentDailyChallengesView";
import RankingChallengesView from "@/views/RankingChallengesView";
import ResetPassword from "@/views/ResetPassword";
import ForgotPassword from "@/views/ForgotPassword";
import ProfileView from "@/views/ProfileView.vue";


const routes = [
        {path: '/forgot-password', component: ForgotPassword},
        {path: '/reset-password', component: ResetPassword},
        {path: '/', component: LoginAdmin},
        {path: '/dashboard', name: 'DashboardView', component: Dashboard},
        {path: '/login', name: 'Login', component: LoginView},
        {path: '/admin', component: LoginAdmin},
        {path: '/register', name: 'Register', component: RegisterView},
        {path: '/school', name: 'SchoolCreateView', component: SchoolCreateView},
        {path: '/classes', name: 'ClassesView', component: ClassesView},
        {path: '/classe/:id', name: 'ClasseView', component: ClasseView},
        {path: '/students', name: 'StudentsView', component: StudentView},
        {path: '/teachers', name: 'TeachersView', component: TeacherView},
        {path: '/challenges', name: 'ChallengesView', component: ChallengeView},
        {path: '/ranking/challenges/:id', name: 'RankingChallengesView', component: RankingChallengesView},
        {path: '/students/:id/challenges', name: 'StudentChallengesView', component: StudentChallengesView},
        {path: '/students/:studentId/challenges/:challengeId/daily', name: 'StudentDailyChallengesView', component: StudentDailyChallengesView},
        {path: '/own/challenges', name: 'StudentOwnChallengesView', component: StudentOwnChallengesView},
        {path: '/own/challenges/:id/daily', name: 'StudentOwnDailyChallengesView', component: StudentOwnDailyChallengesView},
        {path: '/profile', name: 'ProfileView', component: ProfileView},
        {path: '/logout', name: 'LogoutView', component: LogoutView},
    ]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

export default router