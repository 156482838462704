<template>
  <h1>Logout...</h1>
</template>

<script>

export default {
  name: "LogoutView",
  mounted: function() {
    const self = this;
    this.$store.dispatch('logoutAction').then(function (){
      localStorage.setItem('token', '');
      self.connect = localStorage.getItem('token');
      self.$router.push('/dashboard');
    }, function (error){
      localStorage.setItem('token', '');
      self.$router.push('/dashboard');
      console.log("Error logout: "+error)
    });
  }
}
</script>

<style scoped>

</style>