import {Promise} from "es6-promise";
import 'es6-promise'

const axios = require('axios');

const instance = axios.create({
    // localBaseURL: 'http://127.0.0.1:8000/api',
    // onlineBaseURL: 'https://epsasport-dev.apps.lu/api',
    // baseURL: 'https://epsasport-dev.apps.lu/api',
    baseURL:  process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})
const student_store =  {
    namespaced: true,
    state: {
        student:{
            id: -1,
            name: '',
            email: '',
            classe: ''
        },
        allStoreStudents: '',
        allStoreStudentsPagination: [],
        meta:{},
        allStoreStudentsChallengesPagination: [],
        metaChallenges:{},
        allStoreStudentOwnChallengesPagination:[],
        metaOwnChallenges:{},
        allStoreStudentDailyChallenges: [],
        allStoreStudentOwnDailyChallenges:[],
        rankingChallenge:[],
    },
    mutations: {
        setStudent: function (state, student){
            state.allStoreStudents = student.data;
        },
        setStudentPagination: function (state, student){
           state.allStoreStudentsPagination = student.data;
           state.meta = student.meta;
        },
        setStudentChallengesPagination: function (state, challenge){
            state.allStoreStudentsChallengesPagination = challenge.data;
            state.metaChallenges = challenge.meta;
        },
        setStudentOwnChallengesPagination: function (state, challenge){
            state.allStoreStudentOwnChallengesPagination = challenge.data;
            state.metaOwnChallenges = challenge.meta;
        },
        setStudentDailyChallenges: function (state, challenge){
            state.allStoreStudentDailyChallenges = challenge.data;
        },
        setStudentOwnDailyChallenges: function (state, challenge){
            state.allStoreStudentOwnDailyChallenges = challenge.data;
        },
        setRankingChallenge: function (state, challenge){
            state.rankingChallenge = challenge.data;
        },
    },
    actions: {
        deleteStudent: ({commit}, studentInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.delete('/student/delete/'+studentInfos.student_id, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        console.log(error);
                        reject(error);
                    });
            });

        },

        getStudentList: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/students/list', config)
                    .then(function (response){
                        console.log(response.data);
                        commit('setStudent', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setClasses', 'error_get_classes');
                        reject(error);
                    });
            });
        },
        getStudentListPagination({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            data.search = data.search ? data.search : '';
            return new Promise((resolve, reject) => {
                instance.get(`/students/listPagination?page=${data.page}&search=${data.search}`, config)
                    .then(function (response){
                        commit('setStudentPagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },
        getStudentChallengesPagination({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/students/${data.id}/challenges/listPagination?page=${data.page}`, config)
                    .then(function (response){
                        commit('setStudentChallengesPagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },
        getStudentOwnChallengesPagination({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/own/challenges/listPagination?page=${data.page}`, config)
                    .then(function (response){
                        commit('setStudentOwnChallengesPagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },
        getStudentDailyChallenges({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/student/${data.studentId}/challenge/${data.challengeId}/list`, config)
                    .then(function (response){
                        commit('setStudentDailyChallenges', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },
        getRankingChallenge({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`ranking/challenges/${data.challengeId}`, config)
                    .then(function (response){
                        commit('setRankingChallenge', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },
        getStudentOwnDailyChallenges({commit}, data){
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/student/challenge/${data.id}/list`, config)
                    .then(function (response){
                        commit('setStudentOwnDailyChallenges', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });
        },

        saveStudent: ({commit}, studentInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/student/register',studentInfos, config)
                    .then(function (response){
                        // commit('setStudent', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        importFile: ({commit}, data) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/students/import',data, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        updateStudent: ({commit}, studentInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post(`/student/modify/${studentInfos.id}`,studentInfos.student, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
    }
}

export default student_store;

