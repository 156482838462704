<template>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
          <form class="form">
            <div class="card card-login card-hidden">
              <div class="card-header card-header-primary text-center">
                <span class="card-title">Add New Schools  </span>
              </div>
              <div class="card-body ">
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">account_circle</i>
                      </span>
                    </div>
                    <input type="text" v-model="name" class="form-control" placeholder="School name..." required>
                  </div>
                </span>

                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email" v-model="email" class="form-control" placeholder="School email..." required>
                  </div>
                </span>

                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">pin_drop</i>
                      </span>
                    </div>
                    <input type="text" v-model="address" class="form-control" placeholder="School address..." required>
                  </div>
                </span>

                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">phone</i>
                      </span>
                    </div>
                    <input type="text" v-model="mobile" class="form-control" placeholder="School mobile..." required>
                  </div>
                </span>

              </div>
              <div class="card-footer justify-content-center">
                <a @click="saveSchool()" class="btn btn-rose btn-link btn-lg">Save</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'SchoolCreateView',
  data: function() {
    return {
      id: -1,
      name: '',
      email: '',
      address: '',
      mobile: ''
    }
  },
  beforeMount() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }
    this.$store.dispatch('getSchoolInfos').then(function (response){
      console.log(response, "Get school info ");

    }, function (error){
      console.log("No school registered: "+error)
    });
  },
  methods: {

    saveSchool: function (){
      this.$store.dispatch('saveSchool', {
        id: this.id,
        email: this.email,
        name: this.name,
        address: this.address,
        mobile: this.mobile
      }).then(function (response){
        console.log(response);
      }, function (error){
        console.log(error);
      });
    }
  },
  computed: {
    ...mapState([status])
  },

}
</script>