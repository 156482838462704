<template>
  <nav class="navbar  navbar-absolute navbar-expand-lg" v-if="connect != ''">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-translate">
        <router-link to="/dashboard" class="navbar-brand" >DASHBOARD</router-link>
        <button type="button" class="ml-auto navbar-toggler" data-toggle="collapse" data-target="#navigation-example3">
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navigation-example3">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" :class="computedClass1('/dashboard')"><router-link to="/dashboard" class="nav-link" ><i class="fa fa-home"></i>home</router-link></li>
          <!--          <li class="nav-item" :class="computedClass1('/school')"><router-link to="/school" class="nav-link" >school</router-link></li>-->
          <li class="nav-item" v-if="role!=='student'" :class="computedClass1('/classes')"><router-link to="/classes" class="nav-link" >classes</router-link></li>
          <li class="nav-item" v-if="role!=='student'" :class="computedClass1('/students')"><router-link to="/students" class="nav-link" >students</router-link></li>
          <li class="nav-item" v-if="role!=='student'" :class="computedClass1('/teachers')"><router-link to="/teachers" class="nav-link" >teachers</router-link></li>
          <li class="nav-item" v-if="role==='student'" :class="computedClass1('/own/challenges')"><router-link to="/own/challenges" class="nav-link" >Own challenges</router-link></li>
          <li class="nav-item" :class="computedClass1('/challenges')"><router-link to="/challenges" class="nav-link" >challenges</router-link></li>
          <li class="nav-item" v-if="role==='student'"><router-link to="/profile" class="nav-link" >Profile</router-link></li>
          <li class="nav-item"><router-link to="/logout" class="nav-link" >logout</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MyNavMenu",

  data() {
    return {
      connect: localStorage.getItem('token'),
      role: localStorage.role
    }
  },

  methods:{
    computedClass1(route) {
      let className = 'active';
      if(this.$router.currentRoute.value.path == route)
        return className;
      return '';
    }
  },

  computed: {
    computedClass(route) {
      let className = 'active';
      if(this.$router.currentRoute == route)
        return className;
      return '';
    },

    connexion: function () {
      if(localStorage.getItem('token') == '')
        return false;
      else return true;
    },

  }
}
</script>
<style scoped>
.nav-item{
  padding-right:1em;
  padding-left: 1em;
}
.active{
  background-color: whitesmoke;
}
</style>